import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

function AboutShort() {
    return (
        <section className="bg_orange_light">
            <Container className="py-3">
                <Row>
                    <Col sm={6} className="mt-5">
                        <h3 className="text-secondary">About Pesquisa Editora</h3>
                        <p className="justify-content-evenly">Pesquisa Editora offers access to top-tier, cross-disciplinary
                            journals across the realms of Medical Science, Artificial and multidisciplinary. As a distinguished global
                            publisher of open-access journals, we specialize in clinical, medical, biological,
                            regenerative medicine, neurology, neurosurgery, surgical, forensic sciences, and pathology.
                            Our diverse content platforms serve as bridges connecting academics to valuable knowledge,
                            crafted with the aim of fostering exploration and ensuring swift access to pertinent
                            research and information, regardless of geographical location. Pesquisa Editora, synonymous with
                            Pesquisa Editora, is dedicated to shaping a seamless and enriching scholarly experience for
                            all.</p>
                        <Button as={Link} to="/about-us" className="mt-3 theme_btn" size="lg"><span>Read about us</span></Button>
                    </Col>
                    <Col sm={6}>
                        <img src={process.env.PUBLIC_URL + "/assets/images/word-map-vector.png"}
                             style={{width: '100%'}}/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AboutShort;