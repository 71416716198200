import React, {useState} from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from "../../../components/Logo";
import Search from "../../../components/Search";
import Offcanvas from 'react-bootstrap/Offcanvas';
import {Link, useNavigate} from "react-router-dom";
import './style.scss'
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faLockOpen, faSearch, faUser} from "@fortawesome/fontawesome-free-solid";

function Header({isSticky}) {

    const [toggle, setToggle] = useState(false);
    const showToggle = () => setToggle(true);
    const closeToggle = () => setToggle(false);
    const [searchQuery, setSearchQuery] = useState('')

    const navigate = useNavigate();
    const handleSearch = () => {
        if(searchQuery.length > 2){
            navigate(`/search?query=${searchQuery}`);
        }

    }

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand className="col" as={Link} to="/"><Logo height="50px" /></Navbar.Brand>
                    <Navbar.Text className="col col-md-4 offset-md-2">
                        <Form className="d-md-flex">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Search here..."
                                    name="query"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <Button type="button" variant="outline-secondary" onClick={() => handleSearch()}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </InputGroup>
                        </Form>
                        </Navbar.Text>
                    <Navbar.Collapse className="justify-content-end">
                        <Nav onClick={() => setToggle(false)}>
                            <Nav.Link as={Link} to="/auth/login"><FontAwesomeIcon icon={faLock} /> Login</Nav.Link>
                            <Nav.Link as={Link} to="/auth/register"><FontAwesomeIcon icon={faUser} /> Register</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Navbar bg="theme-color" expand="lg" className={isSticky ? "sticky-top" : ""} id="main-navbar">

                <Container>
                    <Navbar.Offcanvas
                        show={toggle}
                        onHide={closeToggle}
                        className="canvas_header_menu"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-1`}>
                                Menu
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>

                            <Nav onClick={() => setToggle(false)}>
                                <Nav.Link as={Link} to="/">Home </Nav.Link>
                                <Nav.Link as={Link} to="/journals">Our Journals </Nav.Link>
                                <Nav.Link as={Link} to="/join-us">Join Us</Nav.Link>
                                <Nav.Link as={Link} to="/editorial-policy">Editorial Policies</Nav.Link>
                                <Nav.Link as={Link} to="/about-us">About us</Nav.Link>
                                <Nav.Link as={Link} to="/contact-us">Contact us</Nav.Link>
                                <Nav.Link as={Link} to="/auth/register">Submit Manuscript </Nav.Link>

                            </Nav>

                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                    <Navbar.Toggle onClick={showToggle}/>

                </Container>
            </Navbar>

        </>
    )
}

export default Header;