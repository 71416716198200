import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import { useLocation } from 'react-router-dom';
import {searchArticlesList} from "../../../stores/Article/actions";
import {connect, useSelector} from "react-redux";
import {compose} from "redux";
import Loader from "../../../components/Loader";
import ArticleBox from "../../../components/ArticleBox";

function Search({getSearchArticles}) {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if(queryParams.get('query')){
            getSearchArticles(queryParams.get('query'));
        }
    }, [queryParams.get('query')]);

    const {
        isSearchArticleListFetching,
        isSearchArticleListFetchingError,
        searchArticles,

    } = useSelector(state => state?.ArticleReducer);


    return (
        <section className="mt-3 mb-5">
            <Container >
                <h1 className="fs-2">Search for "{queryParams.get('query')}"</h1>
                {isSearchArticleListFetching ?
                    <div className="text-center my-5">
                        <Loader/>
                    </div>
                    : <>
                        {searchArticles?.length > 0 ?
                            searchArticles?.map((articlePublished, index) => (
                                <ArticleBox
                                    articlePublished={articlePublished}
                                    key={index}
                                    journalSlug={articlePublished?.article?.journal?.slug}
                                    journalDetail={articlePublished?.article?.journal}
                                />
                            ))
                            :
                            <h5 className="text-grey">No any relevant data found. Please refine your search with other keywords.</h5>

                        }
                    </>
                }
            </Container>
        </section>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        getSearchArticles: (data) => dispatch(searchArticlesList(data)),
    }
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(Search);