import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import './style.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleRight} from "@fortawesome/fontawesome-free-solid";
import {Link} from "react-router-dom";

function Banner() {
    const bannerImage = process.env.PUBLIC_URL + "/assets/images/banner/banner-1.png";
    return (
        <div id="top-banner" style={{backgroundImage: `url(${bannerImage})`}}>
            <div className="col-sm-6 py-5 px-5 offset-sm-1">
                <h1>Welcome To <br/>Pesquisa Editora</h1>
                <p className="py-3">Embrace open access publishing to enhance the visibility and influence of your
                    medical, artificial intelligence, and multidisciplinary research, amplifying the reach and impact of
                    both your society and academic career.</p>

                <Row>
                    <Col sm={6} className="d-grid">
                        <Button size="lg" as={Link} to="/journals"
                                className="text-white py-2 mb-3 btn-primary">
                            Our journals <FontAwesomeIcon className="ps-3" icon={faArrowAltCircleRight}/>
                        </Button>
                    </Col>
                    <Col sm={6} className="d-grid">
                        <Button size="lg" as={Link} to="/join-us"
                                className="text-white py-2 mb-3 btn-dark">
                            Publish with us <FontAwesomeIcon className="ps-3" icon={faArrowAltCircleRight}/>
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Banner;