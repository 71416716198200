import React, {useEffect} from "react";
import { Col, Container, Row} from "react-bootstrap";
import ArticleCard from "../../../../components/ArticleCard";
import {connect, useSelector} from "react-redux";
import {compose} from "redux";
import {recentArticlePublishedList} from "../../../../stores/Article/actions";

function LatestArticle({recentArticlesFetch}){
    useEffect(() => {
        recentArticlesFetch();
    }, []);

    const {
        isRecentArticleListFetching,
        isRecentArticleListFetchingError,
        recentArticles,

    } = useSelector(state => state?.ArticleReducer);

    return (
        <section className="bg_blue_light">
            <Container fluid className="py-5">
                <h2 className="mb-3">Recently Published Articles</h2>
                <Row xs={1} md={4} className="g-4">
                    {recentArticles?.map((article) => (
                        <Col>
                            <ArticleCard article={article}/>
                        </Col>
                    ))
                    }
                </Row>
            </Container>
        </section>
    )
}
function mapDispatchToProps(dispatch) {
    return {
        recentArticlesFetch: (data) => dispatch(recentArticlePublishedList(data)),
    }
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(LatestArticle);
