import React from "react";
import {Card, Image} from "react-bootstrap";
import './style.scss'

function EditorCard({editor}) {

    return (
        <Card className="editorial_board text-center">
            <Card.Body className=" single-team">
                <Image src={`${editor?.photo}`}
                       className="team-thumb rounded-circle"/>
                <h6>{editor?.first_name + " " + editor?.last_name}</h6>
                <p className="text-dark text-center">{editor?.country}</p>
                <div className="overlay_content">
                    <div className="content_detail text-center">
                        <b className="text-white">{editor?.first_name + " " + editor?.last_name}</b>
                        <p className="text-dark">{editor?.country}<br/>
                            <small>{editor?.affiliation}</small><br/>
                            <small className="text-uppercase">{editor?.journal?.short_code}</small>
                        </p>
                    </div>
                </div>
            </Card.Body>
        </Card>

    );
}

export default EditorCard;