import React from "react";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";

function ArticleCard({article}) {

    return (
        <Card className="card_effect_1" key={article.id}>
            <Card.Img variant="top" src={article?.article.journal?.banner} style={{height: '50px'}}/>
            <Card.Body>
                    <h6 style={{height: '70px'}}>
                        <Link to={`/journal/${article?.article.journal.slug}/article/${article?.id}`}
                             >{article?.article?.title.length > 110 ? article?.article?.title.slice(0, 110)+ "..." : article?.article?.title}
                        </Link></h6>
                    <div style={{height: '50px'}}>
                        <Link to={article?.doi_url} className="small text-grey">{article?.doi_url}</Link>
                    </div>
                    <span className="badge bg-dark">{article.article?.article_type}</span>

                    <h6><Link to={`/journal/${article?.article.journal.slug}`}
                              className="text-secondary">{article?.article.journal?.name}</Link></h6>
                </Card.Body>
        </Card>

    );
}

export default ArticleCard;