import React, {useEffect, useState} from "react";
import {Col, Container, Image, ListGroup, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import Loader from "../../../../../components/Loader";

function PageContent() {
    const {
        isJournalEditorialBoardFetching,
        isJournalEditorialBoardFetchingError,
        journalEditorialBoards,
        journalDetail
    } = useSelector(state => state?.CommonJournalReducer);

    const [chiefEditors, setChiefEditors] = useState([]);
    const [normalEditors, setNormalEditors] = useState([]);
    useEffect(() => {
        if (journalEditorialBoards.length > 0) {
            setChiefEditors(journalEditorialBoards.filter(editor => editor.chief_editor === 1));
            setNormalEditors(journalEditorialBoards.filter(editor => editor.chief_editor === 0));

        }

    }, [journalEditorialBoards.length > 0]);

    return (
        <Container>
            <Row>
                <Col sm={4}>
                    <ListGroup className="simple-list sort_link_fixed">
                        <ListGroup.Item className="fw-bold">Quick links</ListGroup.Item>
                        <ListGroup.Item as="a" href="#editorial">Editorial Board</ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col sm={8} className="pb-5 ">
                    <article id="#editorial" className="mb-4">
                        {isJournalEditorialBoardFetching ?
                            <div className="text-center my-5">
                                <Loader/>
                            </div>

                            : journalEditorialBoards?.length > 0 ?
                                <div>
                                    {chiefEditors?.length > 0 &&
                                        <>
                                            <h2>Editor in Chief</h2>
                                            {chiefEditors?.map((editorial, index) => (
                                                <div className="d-flex mb-4">
                                                    <div>
                                                        <Image roundedCircle src={editorial?.photo}
                                                               style={{height: '70px', width: '70px'}}/>
                                                    </div>
                                                    <div className="ps-3">
                                                        <b>{editorial?.first_name + " " + editorial?.last_name}</b>
                                                        <br/>
                                                        <small>{editorial?.affiliation}</small><br/>
                                                        <b>{editorial?.country}</b>
                                                        <br/>
                                                        <div className="d-flex gap-3  py-1">
                                                            {editorial?.orcid &&
                                                                <a href={editorial?.orcid} target="_blank"><img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/orcid.logo.icon.svg`}
                                                                    style={{height: '30px'}}/></a>
                                                            }
                                                            {editorial?.scopus_link &&
                                                                <a href={editorial?.scopus_link} target="_blank"><img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/scopus.jpg`}
                                                                    style={{height: '30px'}}/></a>
                                                            }
                                                            {editorial?.web_science_link &&
                                                                <a href={editorial?.web_science_link}
                                                                   target="_blank"><img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/web-science.jpg`}
                                                                    style={{height: '30px'}}/></a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </>
                                    }
                                    {normalEditors?.length > 0 &&
                                        <>
                                            <h2>Editorial Board</h2>
                                            {normalEditors?.map((editorial, index) => (
                                                <div className="d-flex mb-4">
                                                    <div>
                                                        <Image roundedCircle src={editorial?.photo}
                                                               style={{height: '70px', width: '70px'}}/>
                                                    </div>
                                                    <div className="ps-3">
                                                        <b>{editorial?.first_name + " " + editorial?.last_name}</b>
                                                        <br/>
                                                        <small>{editorial?.affiliation}</small><br/>
                                                        <b>{editorial?.country}</b>
                                                        <br/>
                                                        <div className="d-flex gap-3  py-1">
                                                            {editorial?.orcid &&
                                                                <a href={editorial?.orcid} target="_blank"><img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/orcid.logo.icon.svg`}
                                                                    style={{height: '30px'}}/></a>
                                                            }
                                                            {editorial?.scopus_link &&
                                                                <a href={editorial?.scopus_link} target="_blank"><img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/scopus.jpg`}
                                                                    style={{height: '30px'}}/></a>
                                                            }
                                                            {editorial?.web_science_link &&
                                                                <a href={editorial?.web_science_link}
                                                                   target="_blank"><img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/web-science.jpg`}
                                                                    style={{height: '30px'}}/></a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </>
                                    }
                                </div>

                                :
                                <h4>No data found</h4>
                        }

                        {isJournalEditorialBoardFetchingError &&
                            <h4 class="text-danger">{isJournalEditorialBoardFetchingError}</h4>
                        }
                    </article>

                </Col>
            </Row>
        </Container>
    )
}

export default PageContent;